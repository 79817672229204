// Access values using siteLayout() in _functions.scss



//Barkman Colors
// $white : #FFFFFF;
// $orange : #ee5544;
// $black :#555759;

// $lightGrey: #acacac;
// $mediumGrey:#555759;


// @font-face {
//   font-family: 'source-sans-light';
//   src: url('../fonts/sourcesanspro/sourcesanspro-light-webfont.eot');
//   src: url('../fonts/sourcesanspro/sourcesanspro-light-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/sourcesanspro/sourcesanspro-light-webfont.woff2') format('woff2'),
//          url('../fonts/sourcesanspro/sourcesanspro-light-webfont.woff') format('woff'),
//          url('../fonts/sourcesanspro/sourcesanspro-light-webfont.ttf') format('truetype'),
//          //look inside svg for id
//          url('../fonts/sourcesanspro/sourcesanspro-light-webfont.svg#source_sans_prolight') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }



$siteLayout: (
  lineHeight: 1.4,
  margin:     4%,
  padding:    20px,
  width:      1000px
);



// Access values using color() in _functions.scss
// THEME: edit (topBar, breadCrumbNumber)

//Rochester Colours

$color: (
  "siteBackground": #EDEDED,
  "formInput": #727272,
  "text":      #727272,
  "selection": #8d8d8d,
  "topBar":    #333333,
  "accentColor": #a05b49,
  "accentColor2": #f7b334,
  "breadCrumbNumberBackground": #3f3f3f,
  "breadCrumbBase": #aeaeae,
  "breadCrumbBaseGoBack": #7D7D7D,
  "breadCrumbNumber": #3f3f3f,
  "breadCrumbCurrentNumber": #888888,
  "breadCrumbGoBackNumber": #3f3f3f,
  "breadCrumbBackgroundColour": #727272,
  "breadCrumbCurrentBackground": #D2D2D2,
  "buttonBgColour": #4E8ABE,
  "buttonBgColourOver": #4E8ABE,
  "buttonBgColourRollover": #3BA4FF,
  "cmsSidebarBgColour": #1a1a1a,
  "cmsSidebarLinkColour": #a7a7a7,
  "cmsSidebarHeaderColour": #d9d9d9,
  "labelColour": #1b1515,
  "linkHoverColour": #f7b334
);

/*
// Barkman colours
$color: (
  "siteBackground": #EDEDED,
  "formInput": #6c6d70,
  "text":      #6c6d70,
  "selection": #b3d4fc,
  "topBar":    #4788b9,
  "accentColor": #4a80ae,
  "accentColor2": #4a80ae,
  "breadCrumbNumberBackground": #778386,
  "breadCrumbBase": #96B9D4,
  "breadCrumbBaseGoBack": #4788B9,
  "breadCrumbNumber": #778386,
  "breadCrumbCurrentNumber": #4788B9,
  "breadCrumbGoBackNumber": #000000,
  "breadCrumbBackgroundColour": #9DC0DA,
  "breadCrumbCurrentBackground": #ffffff,
  "buttonBgColour": #4E8ABE,
  "buttonBgColourOver": #3BA4FF,
  "buttonBgColourRollover": #3BA4FF,
  "cmsSidebarBgColour": #4788b9,
  "cmsSidebarLinkColour": #EDEDED,
  "cmsSidebarLinkHoverColour": #EDEDED,
  "cmsSidebarHeaderColour": #EDEDED,
  "labelColour": #596f73,
  "linkHoverColour": #4a80ae
);

*/

// Access values using fontSize() in _functions.scss

$fontSize: (
  largest: 40px,
  larger:  32px,
  large:   24px,
  medium:  18px,
  normal:  16px,
  small:   12px
);



// Access values using fontStack() in _functions.scss
// Write stack as a single string to avoid compile errors

$fontStack: (
  default:   '"Roboto", sans-serif',
  monospace: 'monospace, monospace'
);



// $zList will generated a SASS map with a value for each item
// The farther down the list the higher the value
// Access values using zIndex() in _functions.scss
// E.G. zIndex(bottom) = 1, zIndex(middle) = 2, etc.

$zList:
  bottom,
  middle,
  top;

$zIndex: ();

$zCount: 1;

@each $item in $zList {

  $zIndex: map-merge($zIndex, ($item: $zCount));
  $zCount: $zCount + 1;
}


